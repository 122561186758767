@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url('./variables.css');

body {
  font-family: 'Mulish', sans-serif !important;
  background: #fff;
}


.blur {
  background: #e4effb;
  background-image: url(./assets/img/welcome_banner.svg);
  filter: blur(10px);
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh - 60px);
  margin-top: 60px;
}

.cardLogin {

  z-index: 1;

}

.btn-primary {
  color: #ffffff !important;
  background-color: #02015E !important;
  border-color: #02015E !important;
}

.btn-primary:hover {
  color: #ffffff !important;
  background-color: #12526d !important;
  border-color: #02015E !important;
}

.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-link.collapsed i:hover,
.sidebar-nav .nav-link i:hover,
.sidebar-nav .nav-link a:hover,
.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-item:hover,
.sidebar-nav .nav-link.collapsed:hover {
  color: #02015E !important;
  /*background-color: rgba(0, 0, 0, 0.05);*/
}

.sidebar-nav .nav-content a,
.sidebar-nav .nav-link.collapsed i,
.sidebar-nav .nav-link,
.sidebar-nav .nav-link i,
.sidebar-nav .nav-link.collapsed {
  color: #444 !important;
  background: transparent;
  font-size: 1.1em;
  font-weight: normal;
}

.sidebar-nav .nav-link.collapsed i:hover,
.sidebar-nav .nav-link i:hover {
  color: #02015E !important;
  background: transparent;
  font-size: 1.1em;
  font-weight: normal;
}


.sidebar {
  background-color: #F5F9Fb;
  border-right: 1px solid #e1f0f7;
}

.element-body .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.element-body .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #02015E !important;
}

/****************************************************************************************/
/*************************** Fin personalizacion colores  ******************************/
/***************************************************************************************/
 
/*--------------------------------------------------------------
  # Main
  --------------------------------------------------------------*/
.main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
  overflow: auto;
}

@media (max-width: 1199px) {
  .main {
    padding: 20px;
  }
}
  
.mat-tab-body .main {
	margin: 0 !important;
	padding: 1em !important;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding: 0 20px;
}

  
.botonera {
  width: 100%;
	display: flex;
	flex-direction: row;
	justify-content:flex-end;
	gap: 10px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-right: 10px;
  padding-left: 0px;
}


.logo span {
  font-size: 14px;
  font-weight: 100;
  color: #000;
  font-family: "Nunito", sans-serif;
}

.logo span b {
  font-size: 19px;
  font-weight: bold;
  color: #02015E !important;
}

.logo span.img {
  background: #fff;
  background-image: url(./assets/img/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
}

span.profilelogo {
  background: #fff;
  background-image: url(./assets/img/user-circle.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.profilelogo.big {
  width: 150px;
  height: 150px;
}



/**** Tabla ******/
.tablaPersonal {
  display: table;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid var(--neutral-200);
  margin-top: 15px;
  overflow: hidden;
}

.tablaPersonal th{
    background-color: #F9FAFB;
    color: var(--neutral-700);
    font-size: var(--body-extra-small);
    font-family: var(--default-font-family);
    font-weight: var(--font-bold);
}

.tablaPersonal td{
  color: var(--neutral-900);
  font-size: var(--body-extra-small);
  font-family: var(--default-font-family);
  font-weight: var(--font-bold);
  border-bottom-color: var(--neutral-200) !important;
}

.charts-empty-state {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: calc(100% - 35px);
  padding: 3em;
  opacity: .3;
}

table {
  width: 100%;
}

.mat-form-field-infix {
  min-width: 60px !important;
  width: auto !important;
}

.tablaPersonal .mat-form-field {
  width: 85% !important;
}

.tablaPersonal td.button div {
  display: flex;
  justify-content: flex-end;
}


.mat-paginator-container {
  justify-content: flex-start !important;
  font-family: var(--default-font-family);
}


.mat-form-field {
  font-size: 14px;
  width: 50%;
  font-family: 'Mulish', sans-serif !important;
}

.mat-column-position {
  max-width: 100px;
}

.element-body .mat-checkbox-disabled .mat-checkbox-label,
.element-body .mat-input-element:disabled,
.element-body .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix:after {
  color: #000000 !important;
}

tr.example-detail-row {
  height: 0 !important;
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: whitesmoke !important;
}

tr.example-element-row:not(.example-expanded-row):active {
  background: #efefef !important;
}

.example-element-row td {
  border-bottom-width: 0 !important;
}

.example-element-detail {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}




.mat-expansion-panel:hover {
  background-color: #ccc;
}

.mat-expansion-panel-header {
  background-color: #e9e9e9;
}

.mat-expansion-panel-header.mat-expanded {
  height: 48px
}

.mat-accordion {
  margin-bottom: 10px;
}

.mat-expansion-panel-content {
  overflow: auto !important;
}

.mat-expansion-panel-body {
  padding: 16px 24px !important;
}



.personalMenu .toc {
  display: none;
}

.personalMenu .nav-item label {
  width: 100%;
}

.personalMenu li[data-level="2"]>input[type="checkbox"]:checked+label>a,
.personalMenu li[data-level="1"]>input[type="checkbox"]:checked+label>a {
  color: #02015E !important;
}

.personalMenu input[type="checkbox"]:checked+label>a {
  background-color: #eee;
  color: #02015E !important;
}

.personalMenu input[type="checkbox"]+label>a {
  width: 100%;
  display: inline-block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 1.1em;
}

.personalMenu a {
  text-decoration: none;
}

.personalMenu li:not(.nav-item) a {
  padding-left: 2em;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.btn-aceptar {
  color: #ffffff !important;
  background-color: #02015E !important;
  border-color: #02015E !important;
  float: right;
  border-radius: 10px;
  padding: 8px 16px;
  margin-right: 8px;
}

.btn-cancelar {
  color: #ffffff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  float: left;
}

.btn {
  color: #ffffff !important;
  background-color: #02015E !important;
  border-color: #02015E !important;
  border-radius: 10px;
  padding: 8px 16px;
  margin-right: 8px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  font-family: 'Mulish', sans-serif !important;
  color: #ffffff !important;
  background-color: #02015E !important;
  border-color: #02015E !important;
  border-radius: 10px;
  font-size: 18px;
}

.btn:hover {
  color: #02015E !important;
  background: transparent !important;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #000 !important;
}


.cardCustos {
  padding: 10px;
  margin-bottom: 10px;
}

.cardCustos mat-checkbox {
  margin-left: 25px;
}

.cardCustos mat-checkbox .mat-checkbox-label {
  margin: 4px 0 0 10px;
}


.pagetitle {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pagetitle > div{
  display: flex;
  gap:var(--spacing-xs)
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: #02015E !important;
  font-size: 24px;
}


ngx-numeric-range-form-field-control {
  margin-top: -10px;
}

@media (min-width: 1200px) {

  #main,
  #footer {
    margin-left: 240px;
  }
}

.custom-modalbox.big {
  height: 90%;
}

.custom-modalbox main {
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding: 0px !important;

}
.red-button {
  background-color: #B42318 !important;
  color: white;
}
.blue-button {
  background-color: #02015E  !important;
  color: white;
}


.sidebar {
  width: 240px !important;
}


.cardCustos .mat-form-field-infix {
  border-top: none
}

.cardCustos .mat-form-field {
  width: 100% !important;
}


.cardCustos .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
}

.cardCustos .mat-input-element {
  caret-color: #dee2e6 !important;
}

.obligatorio {
  color: red
}

.mat-tooltip {
    background-color: rgba(2, 1, 94, 0.8) !important; 
    color: white;
    font-family: 'Mulish', sans-serif !important;
    max-width: 450px !important;
}

.edit-panel {
  position: fixed !important;
  top: 0;
  right: 0;
  width: 760px;
  height: 100%;
  border-radius: 10px;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
  max-height: 100% !important;
  background: none !important;
}

@media (max-width: 700px) {
  .edit-panel {
    width: 100% !important;
    max-width: 100% !important;
  }

  .custom-modalbox{
    width: 90% !important;
    max-width: 100% !important;
  }
}

.edit-panel .title{
  font-size: 18px;
  font-weight: bold;
  color: #1D1D1D;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  color: var(--Typography-Tertiary, #85888E);
  font-family: 'Mulish', sans-serif !important;
  
}

.mat-form-field-appearance-outline input,
.mat-form-field-appearance-outline textarea,
.mat-form-field-appearance-outline select,
.mat-form-field-appearance-outline .mat-select{
  color: var(--Typography-Dark, #1D1D1D);
  font-family: 'Mulish', sans-serif !important;
}


.mat-form-field-disabled .mat-form-field-prefix, 
.mat-form-field-appearance-outline .mat-input-element:disabled,
.mat-select-disabled .mat-select-value {
  color: var(--Typography-Tertiary, #85888E) !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0);
}


.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #001B71 !important;
}

.save-button:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
  cursor: not-allowed;
}

.close-button,
.edit-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}


.cardCustos ul {
  display: flex;
}

.cardCustos ul li {
  width: 300px;
}

mat-option.mat-option {
  font-family: 'Mulish', sans-serif !important;
  letter-spacing: normal;
}

mat-option.mat-body,
mat-option.mat-body-1,
.mat-typography mat-option.mat-body,
.mat-typography mat-option.mat-body-1,
.mat-typography {
  font-family: 'Mulish', sans-serif !important;
  letter-spacing: normal;
}

.Accumulations,
.Comparatives,
.breakdowns,
.Other {
  width: 33%;
}

.Accumulations button,
.Comparatives button,
.breakdowns button,
.Other button {
  position: absolute;
  right: 0;
  top: 11px;
}


.full-screem {
  width: 100%;
}



.card {
  margin-top: 16px;
  margin-bottom: 0px;
}


.apexcharts-canvas {

  width: 100% !important;
}

.apexcharts-canvas svg {

  width: 100% !important;
}

th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
  padding-left: 10px !important;
  /*padding-top: 8px;*/
}

.btnRefesh {
  border-radius: 7px;
  padding: 6px;
  height: 40px;
  border: 1px solid;
}

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  width: 165px !important;
  font-size: 11px;
  padding: 9px 0;
}

.mat-form-field-type-numeric-range-form-control .mat-form-field-infix {
  width: 100px !important;
}

.mat-form-field-infix {
  min-width: 70px !important;
}

.mat-paginator {
  float: right !important;
}

.icoDownload {
  font-size: 30px;
  margin: 14px !important;
}

.numeric-range-field .mat-form-field-flex {
  padding-top: 0 !important;
}

/* Define el estilo para el backdrop específico del edit-panel */
.custom-edit-panel-backdrop.cdk-overlay-dark-backdrop {
  background: none !important;
}

.filterCustos {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filterCard {
  width: 160px;
  margin-right: 20px;
}

.filterCard mat-form-field {
  width: 100% !important;
}

.row-pending {
  background-color: #FFEFC3 !important;
}

.row-OFF {
  background-color: #FFE7E5 !important;
}

.mat-dialog-container {
  overflow: hidden !important;
}

.edit-panel .mat-dialog-container {
  padding: 0px !important;
}


.mat-tab-body-content {
  height: 100%;
  width: 100%;
}

.mat-progress-bar {
  height: 15px !important;
}

.icoTabla {
  width: 20px;
}

.mat-icon-button.iconoTabla {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  color: #344054 !important;
}

.iconoTabla mat-icon, .iconoTabla i{
  font-size: 20px;
}

.mat-icon-button.iconoTabla.delete-icon {
  color: #d32f2f !important;
}

.mat-list-item-content p {
  margin-top: 13px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #001B71;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #FFFFFF;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #001B71;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #001B71;
  border-bottom: 2px solid #001B71;
}

.mat-progress-bar .mat-progress-bar-fill::after{
  background-color: #001B71 !important;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #001B71;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {

  font-family: 'Mulish', sans-serif !important;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {

  font-family: 'Mulish', sans-serif !important;
  font-weight: bold;

}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: bold;
  font-family: 'Mulish', sans-serif !important;
  color: #444444;
}

.header-nav .nav-profile span {
  font-size: 16px;
  font-family: 'Mulish', sans-serif !important;
  font-weight: bold;
  color: #001B71;

}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: #85888E !important;
  background-color: #F2F4F7 !important;
  border-color: #F2F4F7 !important;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {

  border-radius: 10px !important;
  font-family: 'Mulish', sans-serif !important;

}


.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: 'Mulish', sans-serif !important;
  letter-spacing: normal;
  margin: 0 0 16px;
}

i.arrow{

  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.custom-icon-color {
  color: #02015E !important;
}


.close-button {
  font-size: 24px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #333;
}


.toggle-selector .mat-slide-toggle-content{
  font-family: 'Mulish', sans-serif !important;
}


  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  .footer {
    padding: 15px 10px;
    font-size: 14px;
    transition: all 0.3s;
    border-top: 1px solid #cddfff;
    }
    .footer .copyright {
    text-align: center;
    color: #012970;
    }
    .footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #012970;
    }
  
  /*--------------------------------------------------------------
    # Sensors Charts tooltip 
  --------------------------------------------------------------*/
  
  .chart-tooltip {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: 'Mulish', sans-serif;
  }
  
  .chart-tooltip-body {
    display: flex;
    flex-direction: column;
    padding: .5em;
     color: #666;
  }
  
  /*--------------------------------------------------------------
    # Status indicator 
  --------------------------------------------------------------*/
  
  .status-indicator {
    display: flex;
    gap: var(--Spaces-Spacing-XXS, 4px);
    padding: var(--Spaces-Spacing-XXS, 4px) var(--Spaces-Spacing-XS, 8px);
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: var(--Radius-Radius-XXS, 4px);
    border: 1px solid var(--Border-Default, #EAECF0);
    background: var(--Background-White, #FFF);
  }
  
  .status-label{
    color: var(--Typography-Secondary, #344054);
    text-align: center;

    font-family: var(--default-font-family);
    font-size: var(--body-extra-small);
    font-weight: 600;
    line-height: 16px; 
  }

  .status-icon {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  
  .status-icon.red {
    background-color: #F04438; /* Rojo para desconectado */
  }
  
  .status-icon.yellow {
    background-color: #F79009; /* Amarillo para sin perfil */
  }
  
  .status-icon.gray {
    background-color: #404F59; /* Gris para apagado */
  }
  
  .status-icon.green {
    background-color: #17B26A; /* Verde para activo */
  }
  
  .status-icon.blue {
    background-color: #0BCCF9; /* Azul para pendiente */
  }
  
  
  /*--------------------------------------------------------------
    # Close panel button
  --------------------------------------------------------------*/
  
  .close-panel-button{
    display: flex;
    align-items: center;
    color: #333;
    background: transparent;
    border: none;
  }

  .close-panel-button mat-icon{
    line-height: 24px;
  }
  
  /*--------------------------------------------------------------
    # Custom modalBox
  --------------------------------------------------------------*/
  
  .custom-modalbox-big{
    min-width: 50%;
  }
  @media (max-width: 900px) {
    .custom-modalbox-big {
      width: 98% !important;
      max-width: 98% !important;
      height: 98% !important;
    }

  }
  
  
  /*--------------------------------------------------------------
    # Battery level Column
  --------------------------------------------------------------*/
  .battery-level{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5em;
  }
  
  .battery-level--default mat-icon{
    color: #404F59;
  }
  
  .battery-level--low mat-icon{
    color: #F04438;
  }
  
  .battery-level--mid mat-icon{
    color: #F79009;
  }
  
  .battery-level--high mat-icon{
    color: #17B26A;
  }
  
  
  /*--------------------------------------------------------------
    # Estilos para tabla general
  --------------------------------------------------------------*/
  .tablaPersonal .mat-paginator-page-size-select{width: 45px !important; }
  .tablaPersonal .mat-form-field-infix{min-width: 45px !important; }


  /*--------------------------------------------------------------
    # Estilos para panel de edición
  --------------------------------------------------------------*/

  .edit-container {
    background-color: var(--neutral-white);
    padding: var(--spacing-s);
    margin: var(--spacing-s);
  }

  .edit-container:last-child {
    margin-bottom: var(--spacing-xxxl); 
  }
  
  .edit-container .mat-form-field {
    width: 100%;
  }

  .edit-content {
    margin-top: var(--spacing-m);
  }

  .bordered {
    border: 1px solid var(--neutral-200);
    box-shadow: var(--shadow-xs);
    border-radius: var(--radius-m);
  }
  

  /*--------------------------------------------------------------
    # Text definitions
  --------------------------------------------------------------*/

  .header-large{
    font-family: var(--default-font-family);
    font-size: var(--header-large);
    font-weight: 600;
    line-height: 28px;
    color: var(--neutral-900);
  }

  .header-medium{
    font-family: var(--default-font-family);
    font-size: var(--header-medium);
    font-weight: 600;
    line-height: 28px;
    color: var(--neutral-900);
  }

  /*--------------------------------------------------------------
    # Buttons definitions
  --------------------------------------------------------------*/
  
  .ct-button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xxs);
    padding: var(--spacing-xs) var(--spacing-s);
    border-radius: var(--radius-xs, 8px);

    /*elements/S/Semibold*/
    font-size: var(--body-small);
    font-weight: var(--font-bold);
  }
  
  /* Primary */
  .ct-button--primary{
    color: var(--neutral-white);
    border: transparent;
    background: var(--primary-02);
    box-shadow: var(--shadow-xs);
  }

  .ct-button--primary:hover{
    background: var(--primary-01);
  }

  .ct-button--primary:focus{
    box-shadow: var(--shadow-focus-primary);
  }

  .ct-button--primary:disabled{
    background: var(--neutral-100);
    border-color: var(--neutral-200);
    color:var(--neutral-500)
  }

  .ct-button--primary.red{
    border: 1px solid var(--red-dark);
    background: var(--red-dark);
  }

  .ct-button--primary.red:hover{
    background: var(--red-very-dark);
  }


  /* Secondary */
  .ct-button--secondary{
    color: var(--neutral-900);
    border: 1px solid var(--neutral-300);
    background: var(--neutral-white);
    box-shadow: var(--shadow-xs);
  }

  .ct-button--secondary:hover{
    background: var(--neutral-100);
  }

  .ct-button--secondary:focus{
    box-shadow: var(--shadow-focus);
  }

  .ct-button--secondary:disabled{
    border-color: var(--neutral-200);
    color:var(--neutral-500)
  }
  
  /* Tertiary */
  .ct-button--tertiary{
    color: var(--neutral-700);
    border: none;
    background: transparent;
  }

  .ct-button--tertiary:hover{
    background: var(--neutral-100);
  }

  .ct-button--tertiary:disabled{
    color:var(--neutral-500)
  }

  /* Link */
  .ct-button--link{
    color: var(--primary-02);
    border: none;
    background: transparent;
  }

  .ct-button--link:hover{
    background: var(--neutral-100);
    color: var(--primary-01);
  }

  .ct-button--link:focus{
    color: var(--neutral-900)
  }

  .ct-button--link:disabled{
    color: var(--neutral-500)
  }

  /* Icon */
  .ct-button--icon{
    border: none;
    background: transparent;
    color: var(--primary-02);
    padding: var(--spacing-xs);
    opacity: 1;
  }

  .ct-button--icon:hover{
    background: var(--neutral-100);
    color:var(--neutral-500)
  }

  .ct-button--icon:focus{
    color:var(--neutral-900)
  }
  
  .ct-button--icon:disabled{
    opacity: .5;
  }
  
  /* Iconos en botones */
  mat-icon.ct-icon{
    width: 20px;
    height: 20px;
    line-height: 18px;
  }

  .ct-icon.red{
    color: var(--red-very-dark);
  }

  .ct-icon.green{
    color: var(--green-very-dark);
  }

  .ct-icon.blue{
    color: var(--primary-01);
  }
    
/*--------------------------------------------------------------
    # Dialog definitions
  --------------------------------------------------------------*/

  .ct-dialog-header{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-m);
  }

  .ct-dialog-content{
    max-height: calc(100vh - 180px) !important;
  }
  
  .ct-dialog-content.ct-dialog-content__extended{
    max-height: calc(100vh - 84px) !important;
  }
  
/*--------------------------------------------------------------
    # 
  --------------------------------------------------------------*/

  .entero-true{
    width: 100%;
  }
  
  @media (max-width: 900px) {
    .hide-on-mobile {
      display: none;
    }
  }
    

/*--------------------------------------------------------------
    # Selector With Filter
  --------------------------------------------------------------*/
  .ct-select-container .mat-mdc-text-field-wrapper{
    height: 54px;
    margin-top: .5em;
  }

  .ct-select-container mat-error{
    font-size: 85%;
    font-family: var(--default-font-family);
    letter-spacing: 0;
  }

  .ct-select-container .mat-mdc-form-field-hint-wrapper,
  .ct-select-container .mat-mdc-form-field-error-wrapper{
    padding: 0 1em;
  }

  mat-label.ct-select-label,
  .ct-select .mat-mdc-select-value {
    font-family: var(--default-font-family);
    font-size: var(--body-small);
    letter-spacing: 0;
  }
  
  .ct-select-container .mdc-floating-label--required::after{
    font-size: 14px;
  }

  .ct-select-container:not(.mat-form-field-invalid) .mdc-notched-outline__leading,
  .ct-select-container:not(.mat-form-field-invalid) .mdc-notched-outline__trailing,
  .ct-select-container:not(.mat-form-field-invalid) .mdc-notched-outline__notch{
    border-color: #e0e0e0;
  }

  .ct-select-container.mat-form-field-invalid .mdc-notched-outline__leading,
  .ct-select-container.mat-form-field-invalid .mdc-notched-outline__trailing,
  .ct-select-container.mat-form-field-invalid .mdc-notched-outline__notch{
    border-width: 2px;
  }

  .ct-select-container.mat-form-field-disabled .mdc-notched-outline__leading,
  .ct-select-container.mat-form-field-disabled .mdc-notched-outline__trailing,
  .ct-select-container.mat-form-field-disabled .mdc-notched-outline__notch{
    border-color: transparent;
  }

  .ct-select-container.mat-form-field-disabled .mat-mdc-select-value{
    color: var(--neutral-500, #85888E) !important;
  }

  .ct-select-dropdown{
    background: var(--neutral-white);
    border: 1px solid var(--neutral-400);
    box-shadow: var(--shadow-m);
  }

  .ct-select-dropdown mat-label{
    width: 100%;
    height: 40px;
    display: block;
  }

  .ct-select-dropdown input{
    height: 40px;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--neutral-400);
    padding: 0px .75em;
  }

  .ct-select-dropdown input:active,
  .ct-select-dropdown input:focus{
    outline: none;
  }
  
  .ct-select-dropdown mat-option{
    color: var(--Typography-Tertiary, #85888E);
    font-family: var(--default-font-family);
    font-size: var(--body-small);
    letter-spacing: 0;
  }

  .ct-select-dropdown__empty span{
    text-align: center;
    color: var(--neutral-500);
  }

  .mat-tab-body-wrapper {
    transition: none !important;
  }
  
  .mat-tab-body {
    transform: none !important;
    animation: none !important;
  }
  